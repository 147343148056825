import { useId } from 'react';
import { colorMap } from '@constants/colors';
import { makeQuarterTurnPath } from '../util';

import { RadialGradient } from './Gradients';

export const QuarterTurn = ({
  size = 100,
  horizontal = 'left',
  vertical = 'down',
  x = 0,
  y = 0,
  fill = 'white',
  stroke,
  opacity = 1,
}) => {
  const gradientId = useId();
  const color = colorMap[fill];

  return (
    <>
      {!stroke && fill && <RadialGradient id={gradientId} color={color} />}
      <path
        d={makeQuarterTurnPath({ size: size, x, y, horizontal, vertical })}
        fill={!stroke && fill ? `url(#${gradientId})` : 'none'}
        stroke={stroke}
        strokeWidth={stroke ? size / 150 : 0}
        style={{ opacity }}
      />
    </>
  );
};
