import React, { useEffect } from 'react';
import cn from 'classnames';

import { trackAnalyticsEvent } from '@util/analytics';
import { getClient } from '@sanity-lib/client';

import Layout from '@components/Layout';
import Illustration, {
  Vertical,
  Horizontal,
  QuarterTurn,
  Package,
} from '@components/Illustration/blocks';
import { makeAnimationOffsetPath } from '@components/Illustration/util';

import textStyles from '@styles/textStyles.module.css';
import themeStyles from '@styles/themes.module.css';
import * as styles from '@styles/404.module.css';
import Button from '@components/Button';
import { fetchWithMenu } from '@sanity-lib/util/queries';

export async function getStaticProps({ draftMode = false }) {
  const client = getClient({ preview: draftMode, useCdn: true });
  const { menu } = await fetchWithMenu(client);
  const seo = { title: '404 - Page not found' };
  return { props: { menu, seo, draftMode } };
}

const [width, height] = [1200, 600];
const size = 140;

const verticalPackageAnimationPath = makeAnimationOffsetPath({
  size: size,
  width: width,
  height: height,
  commands: [
    { type: 'move', x: 3, y: -11 },
    { type: 'line', x: 3, y: 0.5 },
    { type: 'arc', x: 4.5, y: 1.9, radius: 1.5, counterClockwise: true },
    { type: 'line', x: 15, y: 1.9 },
  ],
});

const horizontalPackageAnimationPath = makeAnimationOffsetPath({
  size: size,
  width: width,
  height: height,
  commands: [
    { type: 'move', x: -15, y: 4.7 },
    { type: 'line', x: -2.7, y: 4.7 },
    { type: 'arc', x: -1.2, y: 2.3, radius: 1.5, counterClockwise: true },
    { type: 'arc', x: 0.3, y: 1.9, radius: 1.5 },
    { type: 'line', x: 15, y: 1.9 },
  ],
});

const badPipeAnimationPath = makeAnimationOffsetPath({
  size: size,
  width: width,
  height: height,
  commands: [
    { type: 'move', x: -15, y: 0 },
    { type: 'line', x: -1, y: 0 },
  ],
});

function Background() {
  return (
    <Illustration size={size} width={width} height={height}>
      {/* Bad pipe */}
      <Horizontal
        length={8}
        x={-10}
        y={0}
        stroke="white"
        opacity={0.5}
        direction="right"
      />

      {/* Good pipe */}
      <Vertical
        length={6}
        x={3}
        y={-9}
        fill="blue"
        opacity={0.45}
        direction="down"
      />
      <Vertical
        length={6}
        x={3}
        y={-5}
        stroke="white"
        opacity={0.25}
        direction="down"
      />
      <Horizontal
        length={9}
        x={0}
        y={1.9}
        direction="right"
        fill="yellow"
        opacity={0.25}
      />

      <Horizontal
        length={3}
        x={4}
        y={1.9}
        stroke="white"
        direction="right"
        fill="yellow"
        opacity={0.25}
      />

      <QuarterTurn
        horizontal="right"
        x={4.5}
        y={1.9}
        vertical="up"
        fill="purple"
        opacity={0.65}
      />
      <Horizontal
        length={8}
        x={8.9}
        y={1.9}
        direction="right"
        stroke="white"
        opacity={0.5}
      />
      <QuarterTurn
        horizontal="right"
        x={0.3}
        y={1.9}
        vertical="down"
        fill="teal"
        opacity={0.5}
      />
      <QuarterTurn
        horizontal="left"
        x={-2.7}
        y={4.7}
        vertical="up"
        fill="salmon"
        opacity={0.7}
      />
      <Horizontal
        length={10}
        x={-15}
        y={4.7}
        direction="right"
        fill="purple"
        opacity={0.5}
      />
      <Horizontal
        length={2.7}
        x={-5.2}
        y={4.7}
        direction="right"
        fill="yellow"
        opacity={0.5}
      />

      {/* Packages going the right way from vertical pipe */}
      <Package
        infiniteAnimation
        animationPath={verticalPackageAnimationPath}
        animationDuration={4}
        x={3}
        y={0.5}
        direction="right"
      />

      {/* Packages going the right way from horizontal pipe */}
      <Package
        infiniteAnimation
        animationDuration={5}
        animationDelay={1.5}
        animationPath={horizontalPackageAnimationPath}
        x={0.3}
        y={1.9}
        direction="right"
      />

      {/* Package going down bad pipe */}
      <Package
        animationDuration={1}
        animationPath={badPipeAnimationPath}
        x={-1}
        y={0}
        direction="right"
      />
    </Illustration>
  );
}

export default function NotFoundPage({ menu }) {
  useEffect(() => {
    trackAnalyticsEvent('404error');
  }, []);
  return (
    <Layout menu={menu}>
      <div className={cn(styles.root, themeStyles.dark)}>
        <div className={styles.info}>
          <h1 className={textStyles.headlineXL}>
            <span className={styles.errorCode}>404</span>Page Not Found
          </h1>
          <div className={cn(textStyles.bodyL, styles.text)}>
            The page you are looking for does not exist. It may have been moved.
          </div>
          <div className={styles.actions}>
            <Button href="/" variant="secondary" colorScheme="text" withArrow>
              Go back home
            </Button>
          </div>
        </div>
        <div className={styles.background}>
          <Background />
        </div>
      </div>
    </Layout>
  );
}
