import { useId, useMemo } from 'react';
import cn from 'classnames';
import { colorMap } from '@constants/colors';
import { LinearGradient } from './Gradients';

import styles from './Package.module.css';

export const Package = ({
  size = 100,
  x = 0,
  y = 0,
  direction = 'right',
  style,
  opacity = 1,
  animationPath,
  animationDuration = 2.5,
  animationDelay = 0,
  infiniteAnimation = false,
}) => {
  const gradientId = useId();

  const hasAnimation = Boolean(animationPath);

  const supportsAnimation = useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    return (
      hasAnimation &&
      window.CSS.supports('offset-path: path("M -600 300 L 1800 300")')
    );
  }, [hasAnimation]);

  return (
    <>
      <LinearGradient
        id={gradientId}
        color={colorMap.white}
        direction={direction}
      />
      <circle
        className={cn(styles.root, {
          [styles.hasAnimation]: supportsAnimation,
          [styles.infiniteAnimation]: supportsAnimation && infiniteAnimation,
        })}
        cx={supportsAnimation ? 0 : x}
        cy={supportsAnimation ? 0 : y}
        r={size / 2.5}
        fill={`url(#${gradientId})`}
        style={{
          '--animation-duration': `${animationDuration}s`,
          '--animation-delay': `${animationDelay}s`,
          opacity,
          offsetPath: supportsAnimation ? animationPath : null,
          ...style,
        }}
      />
    </>
  );
};
